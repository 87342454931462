#about {
    display: flex;
    flex-direction: column;
    gap: 100px;

    .heroBanner {
        background-size: cover;
        background-position: 50%;
        height: 346px;
        position: relative;

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #6A4E1199;
            color: white;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            padding: 0 80px;
            font-family: var(--poppins);
            font-weight: 700;
            font-size: 50px;
        }
    }

    .text-container {
        padding: 0 80px;
        h1 {
            font-family: var(--poppins);
            font-weight: 700;
            font-size: 30px;
        }

        p {
            margin-top: 20px;
            font-family: var(--epilogue);
            font-weight: 400;
            font-size: 18px;
        }

        img {
            width: 40vw;
            max-width: 575px;
            /* float: right; */
            /* clear: right; */
            margin-left: 15px;
            /* box-shadow: -10px -10px 0 0 #1C4692 */
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.02);
                box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 24%)
            }
        }
    }
}

.react-multiple-carousel__arrow--left {
    left: 0;
    background-color: transparent;
}

.react-multiple-carousel__arrow--right {
    right: 0;
    background-color: transparent;
}

.react-multiple-carousel__arrow:hover {
    background-color: #EBEBEB;
}

.react-multiple-carousel__arrow::before {
    color: rgb(41, 41, 41);
    font-weight: bold;
}

@media  (max-width: 768px) {
    #about {
        .heroBanner {
            .overlay {
                font-size: 40px;
                padding: 0 40px;
            }
        }

        .text-container{
            padding: 0 40px;
            h1{
                font-size: 25px;
            }
            p {
                font-size: 15px;
            }
            img {
                margin-top: 10px;
                width: 50%;
                box-shadow: -5px -5px 0 0 #1C4692
            }
        }
    }
}