.navbar {
    /* height: 120px; */
    padding: 15px 40px;
    font-family: var(--inter);
    box-shadow: 0px 4px 4px 0px #00000040;
    z-index: 2;
    position: relative;
    /* background-color: gray; */

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #124B9821;
        z-index: 1;
    }

    a, .nav-links {
        text-decoration: none ;
        letter-spacing: 0.5px;
        /* font-weight: 400; */
        color: black;
    z-index: 2;
    font-weight: 400;
    letter-spacing: 1px;
        padding: 3px 7px;
        border-radius: 10px;
    }

    .active {
        color: #CDA449;
        font-weight: 600;
        /* background-color: #F5F5F5; */
    }

    .inactive {
        background: linear-gradient(0deg, #CDA449, #CDA449) no-repeat right bottom / 0 2px;
        transition: background-size 350ms;
    }

    .inactive:hover {
        padding-bottom: 2px;
        background-size: 100% 2px;
        background-position-x: left;
    }
}

::-webkit-scrollbar-button{
    display: none !important;
}

::-webkit-scrollbar {
    width: 10px;
  }
   
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.407);
    border-radius: 4px;
  }

.eagle-animation {
    background-color: #ffffffc7;
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    animation: fadeOut 3s forwards; /* 5s delay + 1s fade-out */

    img {
        width: 25%;
    }
}

/* Keyframe to control the opacity */
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    55% { /* 5s of full opacity */
        opacity: 1;
    }
    100% {  /* 1s fade-out */
        opacity: 0;
        display:none;
    }
}

.footer-container {
    padding: 70px;
    height: 505px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home {
    display: flex;
    flex-direction: column;
    gap: 100px;

    .heroBanner {
        display: flex;
        flex-direction: column;
        height: 100vh;
        min-height: 575px;
    }

    h2 {
        text-transform: uppercase;
        font-family: var(--poppins);
        font-size: 30px;
        font-weight: 400;

        span {
            color: #CDA449;
            font-weight: 700;
        }
    }

    .image-container {
        flex: 1;
        background-size: cover;
        background-position: center;
        position: relative;
        font-family: var(--poppins);
        color: white;
        padding: 7% 80px;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #0C3071A6;
            z-index: 1;
        }

        h1,
        h3,
        button {
            position: relative;
            z-index: 2;
        }

        h1 {
            font-size: 50px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        h3 {
            font-size: 25px;
            font-weight: 300;
            width: 50%;
            margin-bottom: 50px;
        }

        button {
            background: none;
            border: 2px solid white;
            color: white;
            padding: 10px 20px;
            box-shadow: inset 0 0 0 0 white;
            transition: color .3s ease-in-out, box-shadow .3s ease-in-out;

            &:hover {
                box-shadow: inset 128px 0 0 0 white;
                color: #0C3071CC;
            }
        }
    }

    .about {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 80px;
        gap: 100px;

        h4,
        h5 {
            font-family: var(--poppins);
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        h5 {
            font-weight: 700;
            font-size: 22px;
        }

        h4 {
            font-weight: 400;
            font-size: 30px;

            span {
                font-weight: 700;
                color: #CDA449;
            }
        }

        p {
            font-family: var(--epilogue);
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        button {
            background-color: #2C2C2C;
            padding: 10px 20px;
            color: white;
            font-family: var(--epilogue);
            border: 1px solid #2C2C2C;
            box-shadow: inset 0 0 0 0 white;
            transition: color .3s ease-in-out, box-shadow .3s ease-in-out;

            &:hover {
                box-shadow: inset 128px 0 0 0 white;
                color: #2C2C2C;
            }
        }

        >div {
            width: 50%;
        }

        img {
            width: 100%;
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.02);
                box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 24%)
            }
        }
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 200px;
        gap: 30px;

        >div {
            flex: 1;
            height: 432px;
            position: relative;
            width: 300px;
            min-width: 300px;
            /* background-color: gray; */
            border-radius: 8px;
            box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 24%);
            transition: height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
            display: flex !important;
            justify-content: center;
            align-items: center;


            p {
                font-family: var(--epilogue);
                font-weight: 600;
                font-size: 18px;
                text-align: center;
                width: 250px;
            }

            &:hover {
                box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 50%);

                .overlay {
                    height: 0;
                }
            }

            .overlay {
                transition: all 0.7s ease-in-out;
                overflow: hidden;
                position: absolute;
                display: flex !important;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #0c3071;
                border-radius: 8px;
                z-index: 1;
                font-family: var(--poppins);
            }

            h1 {
                position: absolute;
                color: #607aaac4;
                font-size: 300px;
                font-weight: 700;
                z-index: 0;
            }

            h3 {
                color: white;
                z-index: 2;
                font-size: 40px;
                text-transform: uppercase;
                font-weight: 700;
                width: min-content;
                text-align: center;
            }

        }
    }

    .services {
        padding: 0 80px;

        h5 {
            font-family: var(--epilogue);
            max-width: 1000px;
            font-size: 18px;
            font-weight: 400;
        }

        .services-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            gap: 30px;
            padding-top: 60px;

            > div {
                width: 375px;
                height: 360px;
                border-radius: 10px;
                border: 1px solid lightgray;
                transition: all 0.3s ease-in-out;

                img {
                    border-radius: 10px 10px 0 0;
                    width: 100%;
                    height: 160px;
                    object-fit: cover;
                }

                &:hover {
                    transform: scale(1.02);
                    box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 24%)
                }
            }

            .services-text {
                padding: 20px;
                font-family: var(--epilogue);

                h5 {
                    font-weight: 600;
                    font-size: 24px;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }

    .governance {
        padding: 0 80px;

        h5 {
            font-family: var(--epilogue);
            max-width: 1000px;
            font-size: 18px;
            font-weight: 400;
            padding-top: 20px;
        }

        .slider-dots {
            display: flex;
            justify-content: center;
            gap: 10px;
            padding: 30px 0;
            button {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                padding: 0;
                border: none;
                background-color: #EBEBEB;
                margin-right: 10px;

                &.active {
                    background-color: gray;
                }
            }
        }
    }

    .contact {
        display: flex;
        padding: 0 80px;

        > div {
            flex: 1;
        }

        img {
            width: 100%;
        }

        p {
            font-family: var(--inter);
        }

        h2 span {
            color: #4446E9;
        }

        input, textarea {
            width: 100%;
            height: 40px;
            border: 1px solid lightgray;
            border-radius: 5px;
            padding: 10px;
            font-family: var(--inter);
            transition: all 0.3s ease-in-out;

            &:focus-visible {
                outline: none;
                border: 1px solid black;
            }
        }

        textarea {
            resize: none;
            height: 150px;
        }

        button {
            background-color: #4446E9;
            padding: 10px 20px;
            color: white;
            font-family: var(--epilogue);
            border: 1px solid #4446E9;
            box-shadow: inset 0 0 0 0 white;
            transition: color .3s ease-in-out, box-shadow .3s ease-in-out;

            &:hover {
                box-shadow: inset 128px 0 0 0 white;
                color: #4446E9;
            }
        }
    }

}
.footer {
    width: 100%;
    font-family: var(--space-grotesk);
    display: flex;
    z-index: 2;
    justify-content: space-around;

    h1 {
        color: white;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 700;
    }

    p, a {
        color: #FFFFFF99;
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
    }

    a {
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .logos {
        display: flex;
        gap: 10px;

        img {
            background-color: #69696926;
            border-radius: 50%;
            padding: 10px;
        }
    }
}

.copyright {
    color: white;
    margin-top: 50px;
    padding-top: 20px;
    border-top: 1px solid #FFFFFF0D;
    width: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: var(--space-grotesk);
    letter-spacing: 0.5px;
}


  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
}

.react-multi-carousel-dot button {
    border: none !important;
    background-color: lightgray !important;
}

.react-multi-carousel-dot--active button {
    background: #080808a3 !important;
}

.react-multi-carousel-dot-list{
    margin-top: 20px !important;
    position: relative !important;
}

.footer-container {
    position: relative;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color:#124B9821;
        z-index: 1;
    }
}

.slider-card {
    width: 360px;
    height: 100%;
    border-radius: 20px;
    background-color: #EBEBEB;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    text-align: center;

    img {
        height: 80px;
    }

    h5 {
        font-family: var(--epilogue);
        font-weight: 600 !important;
        font-size: 18px;
    }

    p {
        font-family: var(--poppins);
        font-weight: 400;
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .navbar {
        a {
            font-size: 14px;
        }
    }

    .slider-card {
        p {
            font-size: 12px;
        }
    }

    .eagle-animation img {
        width: 80%;
    }

    .home {
        .image-container {
            padding: 7% 40px;
        }
        .heroBanner {
            height: max-content;

            h3 {
                font-size: 15px;
                width: 100%;
            }
            
            h1 {
                font-size: 40px;
                margin-top: 50px;
            }
        }

        .contact {
            padding: 0 40px;
            p {
                font-size: 14px;
            }
        }

        .card-container {
            > div {
                width: 100%;
                min-width: 100%;
                padding: 20px;
                height: 350px;

                h1 {
                    font-size: 250px;
                }

                p {
                    font-size: 15px;
                    padding: 20px;
                }
            }
        }

        .services {
            padding: 0 40px;

            h5 {
                font-size: 15px;
            }
            .services-text {
                p {
                    font-size: 12px !important;
                }

                h5 {
                    font-size: 18px !important;
                }
            }
        }

        .governance {
            padding: 0 40px;
            h5 {
                font-size: 15px;
            }
        }


        h2 { 
            font-size: 25px;
        }

        .about {
            padding: 0 40px;
            > div {
                width: 100%;
            }

            h5 {
                font-size: 20px;
            }

            p {
                font-size: 15px;
            }
        }

        .card-container {
            padding: 0 70px !important;
        }
    }

    .footer-container {
        height: max-content;
         padding: 30px;
     }

     .footer {
         flex-direction: column;
         align-items: center;
         gap: 50px;
     }

}